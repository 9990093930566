import React from "react";
import { Link } from "gatsby";

import researchStyles from "./tssResearch.module.css";

import Headline from "../../components/headline";
import HeaderWithTextAndImage from "../../components/headerWithTextAndImage";
import HeroHeader from "../../components/HeroHeader";
import caretRight from "../../images/caret-right.png";
import Head from "../../components/head";

import { graphql } from "gatsby";
export const query = graphql`
  query {
    allContentfulBio(filter: { program: { eq: "Research Scholar" } }) {
      edges {
        node {
          name
          titleOrInstrument
          bioImage {
            file {
              url
            }
          }
        }
      }
    }

    allContentfulTssResearchPage {
      edges {
        node {
          headerImage {
            file {
              url
            }
          }
          headerHeadline {
            headerHeadline
          }
          headerSubheadline {
            headerSubheadline
          }
          section1Image {
            file {
              url
            }
          }
          section1Headline {
            section1Headline
          }
          section1Paragraph {
            section1Paragraph
          }
          tssResearchScholarHeadline
        }
      }
    }
  }
`;

const TssResearch = props => {
  return (
    <>
      <Head title="TSS Research"></Head>
      <HeroHeader
        location={props.location}
        BGImg={
          props.data.allContentfulTssResearchPage.edges[0].node.headerImage.file
            .url
        }
        heading={
          props.data.allContentfulTssResearchPage.edges[0].node.headerHeadline
            .headerHeadline
        }
        subHeading={
          props.data.allContentfulTssResearchPage.edges[0].node
            .headerSubheadline.headerSubheadline
        }
        buttonText="Donate"
      ></HeroHeader>

      <Headline
        title={
          props.data.allContentfulTssResearchPage.edges[0].node.section1Headline
            .section1Headline
        }
        lightBackground={true}
      ></Headline>
      <HeaderWithTextAndImage
        paragraphHeader={false}
        imgRight={
          props.data.allContentfulTssResearchPage.edges[0].node.section1Image
            .file.url
        }
        isFirstElement={false}
        lightBackground={true}
      >
        {" "}
        <p style={{ whiteSpace: "pre-wrap" }}>
          {
            props.data.allContentfulTssResearchPage.edges[0].node
              .section1Paragraph.section1Paragraph
          }
        </p>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
          }}
        >
          <Link
            to="/programs/research/our-research"
            style={{
              textDecoration: "none",
              color: "#ba0c2f",
              fontWeight: "600",
              display: "flex",
              alignItems: "center",
            }}
          >
            Read more about our research
            <img
              src={caretRight}
              style={{ height: "9px", marginLeft: "4px", width: "auto" }}
            ></img>
          </Link>
        </div>
      </HeaderWithTextAndImage>

      <section className={researchStyles.UgaScholarSection}>
        <div className={researchStyles.UgaScholarContainer}>
          <h3>
            {
              props.data.allContentfulTssResearchPage.edges[0].node
                .tssResearchScholarHeadline
            }
          </h3>
          <div>
            {props.data.allContentfulBio.edges.map((edge, index) => {
              return (
                <div key={index} className={researchStyles.scholar}>
                  <Link to={`/bio/${edge.node.name}`}>
                    <div>
                      <img src={edge.node.bioImage.file.url}></img>
                      <div>
                        <h4>{edge.node.name}</h4>
                        <p>{edge.node.titleOrInstrument}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default TssResearch;
